<template>
  <div id="securityDeposit">
    <van-nav-bar title="诚信保证金" right-text="充值记录" left-arrow @click-left="goLastPage" @click-right="goSubPage('pay-list')" />
    <div class="securityDepositStates" v-if="amount > 0">
      <p class="money">您已交<span class="orange">{{amount}}</span>元保证金</p>
      <p class="tip">信誉有保障，用户更信任</p>
    </div>
    <div class="securityDepositStates" v-else>
      <p class="money">您<span class="orange">还没有</span>交保证金!</p>
      <p class="tip">现在就去缴纳吧!</p>
    </div>
    <van-divider content-position="center" :style="{ color: '#000', borderColor: '#000', padding: '0 16px' }">师傅保障金权益</van-divider>
    <div class="securityDepositBenefit">
      <div class="benefitItem flexCenter">
        <span class="Benefit_icon">&#xe660;</span>
        <span>降低服务信息费</span>
      </div>
      <div class="benefitItem flexCenter">
        <span class="Benefit_icon">&#xe6f8;</span>
        <span>优先推单</span>
      </div>
      <div class="benefitItem flexCenter">
        <span class="Benefit_icon">&#xe6c3;</span>
        <span>优先订单展示</span>
      </div>
    </div>
    <!-- <div class="rulesIntroduction flexCenter">
      <img src="https://cdn.yishifu.com/image/20201120105726JKkIrtq5.png" alt="">
      <span>点击了解免保规则介绍</span>
    </div> -->
    <!-- <p class="securityDepositAgree flexCenter">
      <van-checkbox v-model="checked" checked-color="#FF7963">了解并同意 <span class="protocol">《师傅诚信保证金》</span>
      </van-checkbox>
    </p> -->
    <div style="position: fixed; bottom: 0; left: 0; right: 0;z-index:99">
      <van-button color="linear-gradient(to right, #ff6034, #ee0a24)" block @click="addBandCard()">
        我要缴纳
      </van-button>
    </div>
  </div>
</template>
<script>
import TopBar from "@/components/home/TopBar.vue";
export default {
  data() {
    return {
      amount: "",
      checked: true,
    };
  },
  components: {
    TopBar,
  },
  created() {
    this.$http.get("/api/v2/worker/security_deposit").then((res) => {
      console.log("查询保证金", res);
      this.amount = res.data.amount;
    });
  },
  methods: {
    addBandCard() {
      if (this.checked) {
        this.goSubPage("pay");
      } else {
        this.$toast("请勾选同意");
      }
    },
  },
};
</script>

<style lang="less">
#securityDeposit {
  .securityDepositStates {
    margin: 1rem;
    border-radius: 10px;
    box-sizing: border-box;
    // width: 94%;
    padding: 3rem 1.8rem;
    background: #fff;
    // border-radius: 15px;
    text-align: center;
    p {
      font-weight: 600;
      line-height: 5rem;
      &.money {
        font-size: 2.4rem;
      }
      &.tip {
        font-size: 2rem;
        color: #2d2d2d;
      }
      .orange {
        color: orangered;
        font-size: 2.8rem;
        padding: 0 0.5rem;
      }
    }
  }
  .securityDepositBenefit {
    margin: 1rem;
    padding: 0 1.8rem;
    background: #fff;
    justify-content: space-between;
    display: flex;
    border-radius: 10px;
    .benefitItem {
      flex-direction: column;
      width: 30%;
      padding: 2rem 0;
      font-size: 1.2rem;
      span {
        padding: 0.5rem 0;
        &.Benefit_icon {
          font-family: "iconfont";
          font-size: 3rem;
          color: orangered;
        }
      }
    }
  }
  .rulesIntroduction {
    background: #fff;
    padding: 2rem 0;
    width: 100%;
    flex-direction: column;
    font-size: 1.4rem;
    font-family: "iconfont";
    img {
      width: 40%;
    }
  }
  .securityDepositAgree {
    margin-top: 2rem;
    font-size: 1.4rem;
    text-align: center;
    padding: 2rem 0;
    background: #fff;
    .protocol {
      color: saddlebrown;
    }
  }
}
</style>